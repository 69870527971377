<!--
 * @Author: lbh
 * @Date: 2022-06-17 16:06:41
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 10:57:25
 * @Description: 數據列表 
-->
<template>
  <div
    class="dataList-box px-dataList-box"
    :class="`edit_${Xindex}_box`"
    @click="clickItem('box')"
  >
    <div class="title-box">
      <h1
        class="title"
        v-html="$util.rex.getHtml(isEdit ? (configs[`${nowL}title`] || configs.title ||'點我輸入標題') : configs[`${nowL}title`] || configs.title)"
      ></h1>
      <h2
        class="desc"
        v-html="$util.rex.getHtml(isEdit ? (configs[`${nowL}desc`] || configs.desc || '點我輸入詳情') : configs[`${nowL}desc`] || configs.desc)"
      ></h2>
    </div>
    <div class="items-box">
      <div
        v-for="(item,index) in configs.items"
        :key="index"
        class="item px-item"
      >
        <img
          :src="item.icon"
          :style="`border-radius:${item.slider}px`"
          :class="isMobile ? 'px-icon' : 'icon'"
        />
        <div
          class="t-title"
          v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
        ></div>
        <div
          class="t-desc"
          v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc,1)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script> 
import langMixin from '../../mixins/lang';
import { getByName } from "@/js/utils/default_config";
export default {
  name: "dataList",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: "",
          desc: "",
          items: [
            {
              icon: "",
              title: "",
              desc: ""
            }
          ]
        }
      }
    },
    Xindex: {
      default () {
        return '';
      }
    }
  },
  data () {
    return {
      isMobile: false,
      isEdit: false
    }
  },
  created () {
    this.isMobile = this.$util.window._isMobile();
    let isEdit = this.$store.getters.getIsEdit;
    this.isEdit = isEdit;
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.dataList-box {
  padding: 60px 0 60px 0;
  .title-box {
    .title {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      text-align: center;
    }
    .desc {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      text-align: center;
      color: #999999;
      margin-top: 6px;
    }
  }

  .items-box {
    margin-top: 70px;
    padding: 0 290px;
    display: flex;
    flex-wrap: wrap;
    .item {
      display: flex;
      width: calc(33.33% - 76px);
      flex-direction: column;
      align-items: center;
      margin-left: 40px;
      padding: 30px 24px;
      margin-bottom: 60px;
      transition: 0.3s;
      position: relative;
      &.px-item {
        &:hover {
          box-shadow: 4px 4px 15px 1px rgba(0, 0, 0, 0.06),
            -4px -4px 15px 1px rgba(0, 0, 0, 0.06);
          border-radius: 10px;
        }
      }
      .remove {
        position: absolute;
        top: 6px;
        right: 10px;
        font-size: 32px;
        &:hover {
          color: var(--themeColor);
        }
      }
      &.add {
        align-items: center;
        justify-content: center;
        .add-icon {
          font-size: 30px;
        }
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border: 1px dashed #ccc;
        }
      }
      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      .icon {
        width: 76px;
        min-height: 76px;
      }
      .px-icon {
        width: 34px;
        min-height: 34px;
      }
      .t-title {
        font-size: 18px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        margin-top: 30px;
      }
      .t-desc {
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666;
        margin-top: 16px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .px-dataList-box {
    // padding-top: 30px;
    .title {
      font-size: 16px !important;
    }
    .desc {
      font-size: 12px !important;
    }
    .items-box {
      padding: 0;
      justify-content: space-evenly;
      .item {
        width: calc(50% - 40px);
        padding: 26px 0 26px;
        margin: 0;
        margin-top: 11px;
        &:hover {
          box-shadow: none !important;
        }
        .icon {
          width: 45px;
          min-height: 45px;
        }
        .px-icon {
          width: 45px;
          min-height: 45px;
        }
        .t-title {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          margin-top: 10px;
        }
        .t-desc {
          font-size: 14px;
          font-weight: 400;
          color: #383838;
          margin-top: 5px;
          text-align: center;
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .px-dataList-box {
    padding-top: 30px;
    .items-box {
      .item {
        padding: 10px 0;
        width: calc(50% - 10px);
        .icon {
          width: 30px;
          min-height: 30px;
        }
        .px-icon {
          width: 30px;
          min-height: 30px;
        }
        .t-title {
          font-size: 13px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-top: 10px;
          text-align: center;
        }
        .t-desc {
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #383838;
          margin-top: 5px;
        }
      }
    }
  }
}
</style>